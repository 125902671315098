import request from '@/utils/request'

// api地址
const api = {
  page: '/rnt/price/page',
  all: '/rnt/price',
  save: '/rnt/price',
  update: '/rnt/price/update',
  delete: '/rnt/price/',
  templateState: '/sys/dictdata/page',
  chargeMode: '/sys/dictdata/page'

}

/**
 * 查询列表
 * @param params 参数
 * @returns {Promise<Array>}
 */
export function page(params) {
  return new Promise((resolve, reject) => {
    request.get(api.page, {
      params: params
    }).then(res => {
      resolve(res.data)
    }).catch(e => {
      reject(e)
    })
  })
}
/**
 * 查询列表
 * @param params 参数
 * @returns {Promise<Array>}
 */
export function all() {
  return new Promise((resolve, reject) => {
    request.get(api.all).then(res => {
      resolve(res.data)
    }).catch(e => {
      reject(e)
    })
  })
}
/**
 * 根据id删除
 * @param id id
 * @returns {Promise<>}
 */
export function deleteById(id) {
  return new Promise((resolve, reject) => {
    request.delete(api.delete + id).then(res => {
      resolve(res.data)
    }).catch(e => {
      reject(e)
    })
  })
}

/**
 * 添加或修改
 * @param data 参数
 * @returns {Promise<>}
 */
export function save(data, isUpdate) {
  if (isUpdate) {
    return new Promise((resolve, reject) => {
      request.post(api.update, data).then(res => {
        resolve(res.data)
      }).catch(e => {
        reject(e)
      })
    })
  } else {
    return new Promise((resolve, reject) => {
      request.post(api.save, data).then(res => {
        resolve(res.data)
      }).catch(e => {
        reject(e)
      })
    })
  }
}

/**
 * 查询模板状态列表
 * @param params 参数
 * @returns {Promise<Array>}
 */
export function templateState() {
  return new Promise((resolve, reject) => {
    const params = {
      dictCode: 'templateState'
    }
    request.get(api.templateState, {
      params: params
    }).then(res => {
      resolve(res.data)
    }).catch(e => {
      reject(e)
    })
  })
}

/**
 * 查询计费方式列表
 * @param params 参数
 * @returns {Promise<Array>}
 */
export function chargeMode() {
  return new Promise((resolve, reject) => {
    const params = {
      dictCode: 'chargeMode'
    }
    request.get(api.chargeMode, {
      params: params
    }).then(res => {
      resolve(res.data)
    }).catch(e => {
      reject(e)
    })
  })
}
