<!-- 编辑弹窗 -->
<template>
  <a-modal
    :width="680"
    :visible="visible"
    :confirm-loading="loading"
    :title="isUpdate ? '修改计费模板' : '新建计费模板'"
    :body-style="{ paddingBottom: '8px' }"
    @update:visible="updateVisible"
    @ok="save"
  >
    <a-form
      ref="form"
      :model="billingForm"
      :rules="billingRules"
      :label-col="{ md: { span: 6 }, sm: { span: 24 } }"
      :wrapper-col="{ md: { span: 18 }, sm: { span: 24 } }"
    >
      <a-row :gutter="16">
        <a-col :md="24" :sm="24" :xs="24">
          <a-form-item label="名称" name="priceName">
            <a-input
              v-model:value="billingForm.priceName"
              placeholder="请输入名称"
            ></a-input>
          </a-form-item>
          <a-form-item label="所属运营商" name="operatorId">
            <a-select
              v-model:value="billingForm.operatorId"
              placeholder="请选择所属运营商"
              @change="changeOperator"
            >
              <a-select-option
                v-for="item in operatorList"
                :key="item.operatorId"
                :value="item.operatorId"
              >
                {{ item.operatorName }}
              </a-select-option>
            </a-select>
          </a-form-item>
          <a-form-item label="计费方式" name="priceType">
            <a-radio-group
              v-model:value="billingForm.priceType"
              @change="priceTypeChange"
            >
              <a-radio
                :value="item.dictDataCode"
                v-for="(item, index) in chargeModeList"
                :key="index"
              >
                {{ item.dictDataName }}</a-radio
              >
            </a-radio-group>
          </a-form-item>
          <a-form-item label="租金" name="rent">
            <a-input-number
              style="width: 95%"
              :min="0"
              placeholder="请输入租金"
              v-model:value="billingForm.rent"
              allow-clear
            >
            </a-input-number>
            元
          </a-form-item>
          <a-form-item label="押金" name="deposit">
            <a-input-number
              style="width: 95%"
              :min="0"
              placeholder="请输入押金"
              v-model:value="billingForm.deposit"
              allow-clear
            ></a-input-number>
            元
          </a-form-item>
          <a-form-item label="每日违约金" name="penalty">
            <a-input-number
              style="width: 95%"
              :min="0"
              placeholder="请输入每日违约金"
              v-model:value="billingForm.penalty"
              allow-clear
            ></a-input-number>
            元
          </a-form-item>
          <a-form-item label="已签合同押金" name="contractedDeposit">
            <a-input-number
              style="width: 95%"
              :min="0"
              placeholder="请输入已签合同押金"
              v-model:value="billingForm.contractedDeposit"
              allow-clear
            ></a-input-number>
            元
          </a-form-item>
          <a-form-item label="丢失保障服务费" name="lossFee">
            <a-input-number
              style="width: 95%"
              :min="0"
              placeholder="请输入丢失保障服务费"
              v-model:value="billingForm.lossFee"
              allow-clear
            ></a-input-number>
            元
          </a-form-item>
          <a-form-item label="折扣系数" name="discountFactor">
            <a-input-number
              class="inputNumber"
              v-model:value="billingForm.discountFactor"
              :min="1"
              :max="100"
              :step="1"
            />
          </a-form-item>
          <a-form-item label="计费周期" name="cycle">
            <a-input-number
              style="width: 95%"
              :min="0"
              placeholder="请输入计费周期"
              v-model:value="billingForm.cycle"
              allow-clear
            ></a-input-number>
            天
          </a-form-item>
          <a-form-item
            label="计费次数"
            name="count"
            v-if="billingForm.priceType === 'count'"
          >
            <a-input-number
              style="width: 95%"
              :min="0"
              placeholder="请输入计费次数"
              v-model:value="billingForm.count"
              allow-clear
            ></a-input-number>
            次
          </a-form-item>
          <a-form-item
            label="每次价格"
            name="perPrice"
            v-if="billingForm.priceType === 'count'"
          >
            <a-input-number
              style="width: 95%"
              :min="0"
              placeholder="请输入每次价格"
              v-model:value="billingForm.perPrice"
              allow-clear
            ></a-input-number>
            元
          </a-form-item>
          <a-form-item label="所属品牌" name="brandName">
            <a-select
              v-model:value="billingForm.brandName"
              placeholder="请选择品牌"
              @change="changeBatteryBrand"
            >
              <a-select-option
                v-for="item in brandList"
                :key="item.brandName"
                :value="item.brandName"
              >
                {{ item.brandName }}
              </a-select-option>
            </a-select>
          </a-form-item>
          <a-form-item label="所属型号" name="model">
            <a-select
              v-model:value="billingForm.model"
              placeholder="请选择型号"
              @change="changeBatteryModel"
            >
              <a-select-option
                v-for="item in modelList"
                :key="item.model"
                :value="item.model"
              >
                {{ item.model }}
              </a-select-option>
            </a-select>
          </a-form-item>
          <a-form-item label="所属租赁点" name="pointIds">
            <a-select
              v-model:value="billingForm.pointIds"
              placeholder="请选择所属租赁点"
              mode="multiple"
              :showArrow="true"
              @change="pointsChange"
            >
              <a-select-option
                v-for="item in pointList"
                :key="item.pointId"
                :value="item.pointId"
              >
                {{ item.pointName }}
              </a-select-option>
            </a-select>
          </a-form-item>
          <a-form-item label="图片" name="pricePic">
            <a-upload
              list-type="picture-card"
              v-model:file-list="pricePicList"
              @preview="handlePreview"
              :customRequest="({ file }) => uploadFile(file, 'pricePicList')"
              :remove="removeFile"
            >
              <div v-if="pricePicList.length < 1">
                <plus-outlined />
                <div class="ant-upload-text">上传</div>
              </div>
            </a-upload>
            <a-modal
              :visible="previewVisible"
              :footer="null"
              @cancel="previewVisible = false"
              :bodyStyle="{paddingTop:'50px'}"
            >
              <img alt="example" style="width: 100%" :src="previewImage" />
            </a-modal>
          </a-form-item>
        </a-col>
      </a-row>
    </a-form>
  </a-modal>
</template>

<script>
// import * as batteryModelApi from '@/api/rnt/batteryModel'
// import * as operatorApi from '@/api/rnt/operator'
import * as batteryModelApi from '@/api/rnt/batteryModel'
import * as operatorApi from '@/api/rnt/operator'
import * as pointApi from '@/api/rnt/point.js'
import * as priceApi from '@/api/rnt/price.js'
import setting from '@/config/setting'
import { PlusOutlined } from '@ant-design/icons-vue'
function getBase64(pointPhoto) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(pointPhoto)
    reader.onload = () => resolve(reader.result)
    reader.onerror = (error) => reject(error)
  })
}
export default {
  name: 'batteryModelEdit',
  emits: ['done', 'update:visible'],
  props: {
    // 弹窗是否打开
    visible: Boolean,
    // 修改回显的数据
    data: Object
  },
  components: {
    PlusOutlined
  },
  data() {
    return {
      // 表单数据
      billingForm: Object.assign({}, this.data),
      pricePicList: [],
      uploadUrl: setting.uploadUrl,
      // 编辑弹窗表单验证规则
      billingRules: {
        operatorId: [
          {
            required: true,
            message: '请输入所属运营商',
            trigger: 'blur',
            type: 'number'
          }
        ],
        rent: [
          {
            required: true,
            message: '租金不为空且最大6位数,保留小数点2位',
            trigger: 'blur',
            type: 'number'
          }
        ],
        discount: [
          {
            type: 'number'
          }
        ],
        brandName: [
          {
            required: true,
            message: '请选择所属品牌',
            trigger: 'blur',
            type: 'string'
          }
        ],
        deposit: [
          {
            required: true,
            message: '押金不为空且最大6位数,保留小数点2位',
            trigger: 'blur',
            type: 'number'
          }
        ],
        penalty: [
          {
            required: true,
            message: '每日违约金不为空且最大6位数,保留小数点2位',
            trigger: 'blur',
            type: 'number'
          }
        ],
        lossFee: [
          {
            required: true,
            message: '丢失保障服务费不为空且最大6位数,保留小数点2位',
            trigger: 'blur',
            type: 'number'
          }
        ],
        cycle: [
          {
            required: true,
            message: '不为空且最大6位数',
            trigger: 'blur',
            type: 'number'
          }
        ],
        count: [
          {
            required: true,
            message: '不为空且最大6位数',
            trigger: 'blur',
            type: 'number'
          }
        ],
        brand: [
          {
            required: true,
            message: '请选择所属品牌',
            trigger: 'blur',
            type: 'string'
          }
        ],
        model: [
          {
            required: true,
            message: '请选择所属型号',
            trigger: 'blur',
            type: 'string'
          }
        ],
        point: [
          {
            required: true,
            message: '请选择所属租赁点',
            trigger: 'blur',
            type: 'string'
          }
        ]
      },
      // 提交状态
      loading: false,
      // 是否是修改
      isUpdate: false,
      // 项目列表
      modules: [],
      // 电池品牌
      brandList: [],
      // 型号
      modelList: [],
      operatorList: [],
      pointList: [],
      chargeModeList: [],
      previewImage: null,
      previewVisible: false
    }
  },
  watch: {
    data() {
      if (this.data) {
        this.billingForm = this.data
        this.isUpdate = true
        if (this.billingForm.pointIds) {
          this.billingForm.pointIds = this.billingForm.pointIds
            .split(',')
            .map((item) => {
              return parseInt(item)
            })
        } else {
          this.billingForm.pointIds = []
        }
        this.pricePicList = []
        if (this.billingForm.pricePic) {
          this.pricePicList[0] = {
            uid: '-4',
            name: 'image.png',
            status: 'done',
            url: this.billingForm.pricePic
          }
        }
      } else {
        this.billingForm = {}
        this.isUpdate = false
      }
      if (this.$refs.form) {
        this.$refs.form.clearValidate()
      }
      this.queryBrandList()
      this.queryModelList()
    }
  },
  mounted() {
    this.queryOperater()
    this.queryPoint()
    this.querychargeModeList()
    this.queryBrandList()
    this.queryModelList()
  },
  methods: {
    async handlePreview(pointPhoto) {
      if (!pointPhoto.url && !pointPhoto.preview) {
        pointPhoto.preview = await getBase64(pointPhoto.originFileObj)
      }
      this.previewImage = pointPhoto.url || pointPhoto.preview
      this.previewVisible = true
    },
    uploadFile(file, name) {
      const formData = new FormData()
      formData.append('file', file)
      const hide = this.$message.loading('上传中..', 0)
      this.$http
        .post(this.uploadUrl, formData)
        .then((res) => {
          hide()
          if (res.data.code === 0) {
            if (name === 'pricePicList') {
              this.pricePicList[0] = {
                uid: '-4',
                name: 'image.png',
                status: 'done',
                url: res.data.location
              }
            }
            this.billingForm.pricePic = res.data.location
          } else {
            this.$message.error(res.data.msg)
          }
        })
        .catch((e) => {
          hide()
          this.$message.error(e.message)
        })
      return false
    },
    removeFile(file) {
      this.$http
        .delete('/file/remove', {
          path: file.url
        })
        .then((res) => {
          if (res.data.code === 0) {
            if (file.uid === '-4') {
              this.pricePicList = []
              this.billingForm.pricePic = ''
            }
            this.$message.success(res.data.msg)
          } else {
            this.$message.error(res.data.msg)
          }
        })
        .catch((err) => {
          this.$message.error(err)
        })
    },
    /* 保存编辑 */
    save() {
      this.$refs.form
        .validate()
        .then(() => {
          this.loading = true
          // this.form.createUserId = this.$store.state.user.user.userId
          const data = Object.assign({}, this.billingForm, {
            pointIds: this.billingForm.pointIds.join(',')
          })
          priceApi
            .save(data, this.isUpdate)
            .then((res) => {
              this.loading = false
              if (res.code === 0) {
                this.$message.success(res.msg)
                if (!this.isUpdate) {
                  this.billingForm = {}
                }
                this.updateVisible(false)
                this.$emit('done')
              } else {
                this.$message.error(res.msg)
              }
            })
            .catch((e) => {
              this.loading = false
              this.$message.error(e.message)
            })
        })
        .catch(() => {})
    },
    pointsChange(e) {
      console.log(e)
    },
    // 获取计费方式列表
    querychargeModeList() {
      priceApi
        .chargeMode()
        .then((res) => {
          if (res.code === 0) {
            this.chargeModeList = res.data
          } else {
            this.$message.error(res.msg)
          }
        })
        .catch((e) => {
          this.$message.error(e.message)
        })
    },
    // 运营商改变获取对应品牌列表
    changeOperator(value) {
      this.billingForm.brandName = null
      this.billingForm.model = null
      this.billingForm.pointIds = []
      batteryModelApi
        .brand({ operatorId: value })
        .then((res) => {
          if (res.code === 0) {
            this.brandList = res.data
          } else {
            this.$message.error(res.msg)
          }
        })
        .catch((e) => {
          this.$message.error(e.message)
        })
      pointApi
        .page({ operatorId: this.billingForm.operatorId })
        .then((res) => {
          if (res.code === 0) {
            this.pointList = res.data
          } else {
            this.$message.error(res.msg)
          }
        })
        .catch((e) => {
          this.$message.error(e.message)
        })
    },
    // 查询所有电池品牌
    queryBrandList() {
      if (this.billingForm.operatorId) {
        batteryModelApi
          .brand({ operatorId: this.billingForm.operatorId })
          .then((res) => {
            if (res.code === 0) {
              this.brandList = res.data
            } else {
              this.$message.error(res.msg)
            }
          })
          .catch((e) => {
            this.$message.error(e.message)
          })
      } else {
        batteryModelApi
          .brand()
          .then((res) => {
            if (res.code === 0) {
              this.brandList = res.data
            } else {
              this.$message.error(res.msg)
            }
          })
          .catch((e) => {
            this.$message.error(e.message)
          })
      }
    },
    // 查询所有型号
    queryModelList() {
      if (this.billingForm.operatorId && this.billingForm.brandName) {
        batteryModelApi
          .model({
            operatorId: this.billingForm.operatorId,
            brandName: this.billingForm.brandName
          })
          .then((res) => {
            if (res.code === 0) {
              this.modelList = res.data
            } else {
              this.$message.error(res.msg)
            }
          })
          .catch((e) => {
            this.$message.error(e.message)
          })
      } else {
        batteryModelApi
          .model()
          .then((res) => {
            if (res.code === 0) {
              this.modelList = res.data
            } else {
              this.$message.error(res.msg)
            }
          })
          .catch((e) => {
            this.$message.error(e.message)
          })
      }
    },
    // 电池品牌改变获取对应型号
    changeBatteryBrand(value) {
      this.billingForm.model = null
      batteryModelApi
        .model({ brandName: value, operatorId: this.billingForm.operatorId })
        .then((res) => {
          if (res.code === 0) {
            this.modelList = res.data
          } else {
            this.$message.error(res.msg)
          }
        })
        .catch((e) => {
          this.$message.error(e.message)
        })
    },
    changeBatteryModel(value) {
      batteryModelApi
        .page({
          brandName: this.billingForm.brandName,
          operatorId: this.billingForm.operatorId,
          model: value
        })
        .then((res) => {
          if (res.code === 0) {
            this.billingForm.batteryModelId = res.data[0].modelId
          } else {
            this.$message.error(res.msg)
          }
        })
        .catch((e) => {
          this.$message.error(e.message)
        })
    },
    // 计费方式类型改变重置计费次数
    priceTypeChange() {
      this.billingForm.count = null
    },
    /* 查询所属租赁点 */
    queryPoint() {
      pointApi
        .page()
        .then((res) => {
          if (res.code === 0) {
            this.pointList = res.data
          } else {
            this.$message.error(res.msg)
          }
        })
        .catch((e) => {
          this.$message.error(e.message)
        })
    },
    /* 查询运营商 */
    queryOperater() {
      operatorApi
        .page()
        .then((res) => {
          if (res.code === 0) {
            this.operatorList = res.data
          } else {
            this.$message.error(res.msg)
          }
        })
        .catch((e) => {
          this.$message.error(e.message)
        })
    },
    /* 更新visible */
    updateVisible(value) {
      this.$emit('update:visible', value)
    }
  }
}
</script>
<style scoped lang='less'>
.inputNumber {
  width: 100%;
}
</style>
